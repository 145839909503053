import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { BASE_URI } from "../shared/Constants";
import { DealerContext } from "../shared/contexts/DealerContext";
import BrandList from "../shared/BrandList/BrandList";
import ContactSection from "../ContactUs/ContactUs";
import Car from '../shared/assets/car.png'
import Mac from '../shared/assets/car-mechanic.png'
import Events from '../shared/assets/events.png'

const useStyles = makeStyles(() => ({
  rootAbout: {
    margin: "20px 0px",
    background: '#eeeeee',
    position: 'relative',
    paddingRight: '70px',
    paddingTop: '50px',
    '@media (max-width: 614px)': {
      padding: '80px 40px ',
      paddingTop: '20px',
    }
  },
  rootHistory: {
    margin: "20px 0px",
    background: '#eeeeee',
    position: 'relative',
    paddingLeft: '70px',
    paddingTop: '50px',
    '@media (max-width: 614px)': {
      padding: '80px 40px ',
      paddingTop: '20px',
    }
  },
  rootHistoryInner: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  rootHistoryInnerImg: {
    minWidth: "50%",
    paddingRight: '70px',
    '@media (max-width: 614px)': {
      minWidth: 'unset',
    }
  },
  rootHistoryInnerTextHolder: {
    maxWidth: "50%",
    '@media (max-width: 614px)': {
      maxWidth: 'unset',
    }
  },
  root: {
    background: '#eeeeee',
  },
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: '10px 0px',
    transition: '0.5s ease all',
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    },
  },
  imgSectionHolder: {
    background: '#eeeeee',
    paddingTop: "2em",
    paddingBottom: "2em",
    position: 'relative',
  },
  textIntro: {
    fontSize: "16px",
  },
  list: {
    width: 'calc(100% / 2);',
    textAlign: "left"
  },
  MainImg: {
    position: 'relative',
    backgroundSize: 'cover',
    maxHeight: 'calc(90vh - 80px)',
    backgroundPosition: 'center',
    '@media (max-width: 613px)': {
      maxHeight: 'calc(50vh - 80px)',
    },
    '@media (min-width: 614px) and (max-width: 1000px)': {
      maxHeight: 'calc(50vh - 80px)',
    }
  },
  imgSectionAfterSales: {
    backgroundImage: `url(${Events})`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundColor: '#000306a3',
    backgroundBlendMode: 'overlay',
    backgroundPosition: '95% 0%',
    display: 'flex',
    alignItems: 'self-end',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
      top: "0px",
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      bottom: "0px",
      width: '50%',
    },
    '@media (max-width: 600px)': {
      backgroundPosition: '95% 0%',
    }
  },
  imgSectionAfterSalesR: {
    backgroundImage: `url(${Mac})`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundColor: '#01172fa3',
    backgroundBlendMode: 'overlay',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'self-end',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      right: '0px',
      width: '50%',
      top: "0px",
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 10,
      background: ({ websiteColors }) => `${websiteColors.accentColor}`,
      position: 'absolute',
      left: '0px',
      bottom: "0px",
      width: '50%',
    }
  },
  AfterSalesHeading: {
    color: 'white',
    fontSize: '150px',
    textTransform: 'uppercase',
    lineHeight: 1.1,
    '@media (max-width: 959px)': {
      fontSize: '50px',
    }
  },
  AfterSalesHeadingText: {
    color: 'white',
    fontSize: '40px',
    margin: 0,
    '@media (max-width: 600px)': {
      fontSize: '20px',
    }
  },
  AfterSalesButton: {
    background: 'white',
    fontSize: '30px',
    margin: 0,
    padding: '5px 40px',
    borderRadius: '50px',
    cursor: 'pointer',
    marginTop: "40px",
    '@media (max-width: 600px)': {
      fontSize: '18px',
      padding: '5px 20px',
    }
  },
  HeadingHolder: {
    display: 'flex',
    justifyContent: 'end',
  },
  HeadingHolderAfterSales: {
    width: '80%',
    margin: '150px 70px',
    '@media (max-width: 600px)': {
      margin: '80px 40px',
    }
  },
  HeadingHolderAfterSalesR: {
    textAlign: 'end',
    margin: '150px 70px',
    '@media (max-width: 600px)': {
      margin: '80px 40px',
    }
  },
  HeadingBlueAbout: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    position: 'relative',
    zIndex: '2',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  HeadingBlueOverlayAbout: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    left: '50px',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    }
  },
  AboutTextHolder: {
    display: 'flex',
    justifyContent: 'right',
    position: 'relative',
  },
  AboutText: {
    width: '60%',
    textAlign: 'right',
    fontSize: '16px',
    '@media (max-width: 614px)': {
      width: 'unset'
    }
  },
  HistoryTextHolder: {
    justifyContent: 'right',
    position: 'relative',
  },
  HeadingBlueHistory: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    position: 'relative',
    zIndex: '2',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  HeadingBlueOverlayHistory: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    right: '50px',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    }
  },
  HeadingHolderHistory: {
    display: 'flex',
  },
  HistoryText: {
    width: '60%',
    textAlign: 'left',
    fontSize: '16px',
    '@media (max-width: 614px)': {
      width: 'unset'
    }
  },
  ContactSectionHolder: {
    backgroundColor: '#ffffff78',
    backgroundBlendMode: 'overlay',
    padding: '40px 70px',
    '@media (max-width: 959px)': {
      padding: '40px 10px',
    }
  },
  heading: {
    fontSize: "39px",
    textAlign: 'center'
  },
  slideHolder: {
    position: 'relative',
    height: 'calc(100% - 99px)',
    '@media (max-width: 425px)': {
      marginTop: '0px',
    },
  },
  vCardButtonNavFlexMain: {
    display: 'flex',
    width: '80px',
    bottom: '0px',
    right: '0px',
    position: 'absolute'
  },
  vCardButtonNavMain: {
    padding: "10px 10px",
    borderRadius: '50px',
    margin: "10px 0px",
    fill: ({ websiteColors }) => `${websiteColors.accentColor}`,
    cursor: 'pointer'
  },
}));

const HomeComponentBMA = () => {
  const history = useHistory()
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [slides, setSlides] = useState();
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slideIndexMain, setSlideIndexMain] = useState(0);
  const mountedRef = useRef(true);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlides = async () => {
      try {
        const params = {
          pageNumber: 0,
          pageSize: 100,
          status: "active",
          dealerId: globalDealer.id
        };

        const result = await axios({
          method: "GET",
          url: `${BASE_URI}/slide`,
          params,
          cancelToken: source.token,
        });

        let tempSlides = result.data.list.map((itm) => {
          return {
            img: itm.slideImageUrl,
            isVideo: itm.isVideo,
            targetUrl: itm.url
          };
        });

        setSlides(tempSlides);
        setSlidesLoading(false)
      } catch (error) {
        console.warn('There was an error fetching home slides.', error);
      }
    };

    getSlides().then(() => {
      if (!mountedRef.current) return null;
    });

    return () => {
      mountedRef.current = false;
      source.cancel();
    };
  }, []);

  const handleRedirect = () => {
    history.push(`/after-sales`)
  }
  const handleRedirectEvents = () => {
    history.push(`/events`)
  }

  const handleBackSlide = () => {
    if (slideIndexMain > 0) {
      setSlideIndexMain(slideIndexMain - 1)
    }
  };

  const handleNextSlide = () => {
    if (slideIndexMain < slides.length - 1) {
      setSlideIndexMain(slideIndexMain + 1)
    }
    if (slideIndexMain === slides.length - 1) {
      setSlideIndexMain(0)
    }
  };

  return (
    <div className={classes.root}>
      {/* autoscroll */}
      <div className={classes.slideHolder}>
        {!slidesLoading &&
          <img src={slides[slideIndexMain]?.img} width="100%" className={classes.MainImg} />
        }
        <div className={classes.vCardButtonNavFlexMain}>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleBackSlide() }}><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          <svg className={classes.vCardButtonNavMain} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => { handleNextSlide() }}><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
        </div>
      </div>
      <div className={classes.imgSectionHolder}>
        <BrandList />
      </div>
      <div className={classes.imgSectionAfterSales}>
        <div className={classes.HeadingHolderAfterSales}>
          <p className={classes.AfterSalesHeadingText}>Stay up to date with</p>
          <div className={classes.AfterSalesHeading} >Our Events</div>
          <button className={classes.AfterSalesButton} onClick={() => { handleRedirectEvents() }}>Find out more</button>
        </div>
      </div>
      <div className={classes.rootAbout}>
        <div className={classes.HeadingHolder}>
          <div className={classes.HeadingBlueAbout}>ABOUT US</div>
          <h1 className={classes.HeadingBlueOverlayAbout}>ABOUT US</h1>
        </div>
        <div className={classes.AboutTextHolder}>
          <p className={classes.AboutText}>
            <p variant="p" className={classes.textIntro}>
            Established in 1988, Ben Morgenrood Group is a dynamic force in the automotive industry, proudly distributing Ford, GWM, Haval, Mahindra, and Chery vehicles. The group, named after racing legend and co-founder Ben Morgenrood, extends its legacy from the roots of Randfontein Panel beaters, established in 1975.
            </p>
            <p variant="p" className={classes.textIntro}>
            In 2013, our commitment to diversity and excellence led us to expand our dealership to include Mahindra, enriching our offerings to cater to a broader range of automotive needs. The latest addition, Chery Randfontein, and our Mahindra Agri franchise, exemplify our dedication to providing a comprehensive vehicle and agricultural solution range.
            </p>
            <p variant="p" className={classes.textIntro}>
            At Ben Morgenrood Group, our commitment to customer satisfaction is reflected in our dedicated on-site workshops and parts departments for all our brands. Whether you're exploring the latest Ford models, the rugged Mahindra lineup, or the innovative Haval and GWM vehicles, our skilled professionals ensure your vehicle receives top-notch service and genuine parts.
            </p>
            <p variant="p" className={classes.textIntro}>
            From our iconic Ben Morgenrood Ford building illuminating Main Reef Road to our commitment to community engagement, we strive for excellence in every aspect. The Ben Morgenrood Group is not just about vehicles; it's about a rich history, family values, a commitment to shaping the future of automotive excellence, and providing a one-stop destination for all your automotive and agricultural needs.
Join us on a journey where tradition meets innovation, community becomes family, and every need is met under the banner of the Ben Morgenrood Group.
            </p>
          </p>
        </div>
      </div>
      <div className={classes.rootHistory}>
        <div className={classes.HeadingHolderHistory}>
          <div className={classes.HeadingBlueHistory}>OUR HISTORY</div>
          <h1 className={classes.HeadingBlueOverlayHistory}>HISTORY</h1>
        </div>
        <div className={classes.HistoryTextHolder}>
          <p className={classes.HistoryText}>
            Welcome to Ben Morgenrood Group. We are dedicated to fulfilling all of your automotive needs. Consisting of Ford, Mazda, Mahindra, Mahindra Farm equipment, GWM and HAVAL dealerships with Randfontein Panel beaters, Ben Morgenrood Group is your first stop for new and used cars, farm equipment as well as panel beating. Our proud, passionate team are dedicated to service excellence; achieving dealership of the year from 2008-2011.
          </p>
          <p className={classes.HistoryText}>
            In a time when customer service often falls by the wayside, at Ben Morgenrood Group, we are committed to going the extra mile for our customers. We guarantee professionalism and personal attention at all of our dealerships. The Ben Morgenrood Group proudly offers new and used car sales, as well as full servicing and parts sales at each dealership. Whether your brand is Ford, Mazda, GWM, HAVAL or Mahindra, our Ben Morgenrood outlet will offer you all that your vehicle requires for a long life and a smooth drive.
          </p>
          <div className={classes.rootHistoryInner}>
            <div className={classes.rootHistoryInnerTextHolder}>
              <p className={classes.HistoryText}>
                When it comes to fleet solutions and financing, you can count on our capable teams to help you find the solution that suits your needs as well as your budget. Courteous, professional and with years of experience, we work tirelessly to provide you with top quality service and advice. The Ben Morgenrood Group family shares a passion for excellence that you can be sure to find at any one of our outlets.
              </p>
              <p className={classes.HistoryText}>
                Visit the websites for each of our respective brands to view our full range of vehicles and book test drives online or browse through our comprehensive directory right here by exploring our New Cars and Used Cars sections. Keep exploring to keep up to date with special offers and finance solutions. Contact us to find out how we can be a part of your journey, or pay us a visit at our flagship Randfontein branch. We look forward to being a part of your motoring journey.
              </p>
            </div>
            <div className={classes.rootHistoryInnerImg}>
              <img src={Car} width="100%" />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.imgSectionAfterSalesR}>
        <div className={classes.HeadingHolderAfterSalesR}>
          <p className={classes.AfterSalesHeadingText}>Everything you need from</p>
          <div className={classes.AfterSalesHeading} >After Sales</div>
          <button className={classes.AfterSalesButton} onClick={() => { handleRedirect() }}>Find out more</button>
        </div>
      </div>
      <div className={classes.ContactSectionHolder}>
        <ContactSection align="center" heading="Get in touch" subHeading="with us today" hidden={true} home={true} />
      </div>
    </div>
  );
};

export default HomeComponentBMA;

