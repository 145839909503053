import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Finance } from "@red-build/leadcentre";
import { Typography } from "@material-ui/core";
import { DEALERID, MASTER_DEALERID, BASE_URI, FINAPP_URI_APPLICATION } from '../shared/Constants'
import { DealerContext } from "../shared/contexts/DealerContext";
import axios from 'axios'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "100px 15px",
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',

      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold'
  },
}));

const VehicleFinance = () => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [dealerModels, setDealerModels] = useState()

  useEffect(() => {
    let data =
    {
      pageNumber: 1,
      dealerId: 438,
      pageSize: 1000
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      data
    }).then((response) => {
      setDealerModels(response.data.list)
    });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            NEED
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            FINANCE?
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Leave you details, and we will get back to you.
          </Typography>
        </div>
        <div className={classes.section}>
          <Finance
            leadTypeId={1}
            masterDealerId={MASTER_DEALERID}
            dealerId={DEALERID}
            pqType={"interim_pq"}
            dealerModels={dealerModels}
            activeDealerId={globalDealer.id}
            dealers={dealerList}
            base_uri={BASE_URI}
            dealerType={"multi_dealer"}
            pqSuccessUrl={FINAPP_URI_APPLICATION}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleFinance;
