import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { DEALERID } from "../shared/Constants";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "100px 15px",
    display: "flex",
    flexDirection: 'column',

    "@media (max-width: 500px)": {
      padding: "0px 0px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
    },
  },
  innerHolder: {
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    padding: "0px 50px",
    width: '80%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
  card: {
    width: "calc(100% / 3 - 40px )",
    minWidth: "calc(100% / 3 - 40px )",
    cursor: 'pointer',
    borderRadius: '10px',
    margin: "20px ",
    padding: "50px ",
    background: 'white',
    border: "2px solid #eef6f6",
    "&:hover": {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    "@media (max-width: 425px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1  )",
      margin: "20px 0px",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      width: "calc(100% / 2 - 20px )",
      margin: "  10px ",
    },
  },
  cardTextHolder: {
    padding: "0px",
    color: 'white',
    "@media (max-width: 768px)": {},
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  imageHolder: {
    display: 'flex',
    width: '100%',
    height: '70%',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: "contain",
  },
  cardTextTitle: {
    fontSize: '20px',
    textTransform: 'uppercase',
    margin: '5px 0px',
    color: "black"
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    margin: '10px 0px',
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  groupCard: {
    width: "calc(100% / 3 - 40px )",
    minWidth: "calc(100% / 3 - 40px )",
    cursor: 'pointer',
    borderRadius: '10px',
    margin: "20px ",
    padding: "10px ",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    border: "2px solid #eef6f6",
    "&:hover": {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    "@media (max-width: 425px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1  )",
      margin: "20px 0px",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      width: "calc(100% / 2 - 20px )",
      margin: "  10px ",
    },
  },
  groupCardLogo: {
    margin: '10px 0px',
    display: 'flex',
  },
  btnHolder: {
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    padding: "0px 50px",
    width: '80%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
  btn: {
    padding: "10px 35px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    margin: "10px",
    borderRadius: "50px",
    color: "white",
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    border: ({ websiteColors }) => `${websiteColors.accentColor} 2px solid`,
    borderRadius: '50px',
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [vehicles, setVehicles] = useState(newVehiclesList);
  const [vehicleCleaned, setVehicleCleaned] = useState([]);
  const [activeMake, setActiveMake] = useState("");

  useEffect(() => {
    setActiveMake("")
    setVehicles(newVehiclesList);

    let clean = newVehiclesList?.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.make === value.make
      ))
    )

    let branded = [];

    for (let index = 0; index < clean.length; index++) {
      const element = clean[index];
      branded.push(globalDealer.makes.filter((m) => m.id == element.makeId)[0])
    }
    setVehicleCleaned(branded)
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data)
  };

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  const handleFilter = (make) => {
    setActiveMake(make)
  };

  return (
    <>
      <div className={classes.root}>
        {globalDealer.id == DEALERID &&
          <>
            <div className={classes.innerHolder}>
              {activeMake == "" &&
                <>
                  {vehicleCleaned?.map((v, index) => {
                    return (
                      <div className={classes.groupCard} onClick={() => { handleFilter(v?.id) }}>
                        <img src={v?.heroImage} width="100%" />
                        <img src={v?.logo} width="40%" className={classes.groupCardLogo} />
                        {v?.make}
                      </div>
                    )
                  })}
                </>
              }
            </div>
            {activeMake !== "" &&
              <>
                <div className={classes.btnHolder} >
                  <button className={classes.btn} onClick={() => {
                    setActiveMake("");
                  }}>Back</button>
                </div>
                <div className={classes.innerHolder}>
                  {vehicles?.flat()?.filter((v) => v.makeId == activeMake)?.sort((a, b) => (((a.id > b.id || a.title.includes('Tremor'))||((a.title.includes('XUV'))||(a.title.includes('Scorpio N'))))) ? -1 : 1).map((v, index) => {
                    return (
                      <div
                        className={classes.card}
                        onClick={() => {
                          handleRedirect(v);
                        }}
                      >
                        <div className={classes.imageHolder}>
                          {
                            (v.makeId == 8149) ? (
                              <img src={v?.image} width="auto" height="100px" style={{ objectFit: "cover", aspectRatio: '5/2' }} />
                            ) : (
                              <img src={v?.image} width="100%" />
                            )
                          }
                        </div>
                        <div className={classes.cardTextHolder}>
                          <div className={classes.cardTextTitleHolder}>
                            <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                            <h5 className={classes.cardTextTitle}>  <strong><span className={classes.cardTextTitleAccent}>From</span> {formatter.format(v.price)}</strong></h5>
                            <p className={classes.cardTextTitleAccentMore}>More Details</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            }
          </>
        }
        {globalDealer.id !== DEALERID &&
          <div className={classes.innerHolder}>
            {vehicles.flat().sort((a, b) => (((a.id > b.id || a.title.includes('Tremor'))||((a.title.includes('XUV'))||(a.title.includes('Scorpio N'))))) ? -1 : 1).map((v) => {
              return (
                <div
                  className={classes.card}
                  onClick={() => {
                    handleRedirect(v);
                  }}
                >
                  <div className={classes.imageHolder}>
                    {
                      (globalDealer.name.includes('Haval')) ? (
                        <img src={v?.image} width="auto" height="100px" style={{ objectFit: "cover", aspectRatio: '5/2' }} />
                      ) : (
                        <img src={v?.image} width="100%" />
                      )
                    }
                  </div>
                  <div className={classes.cardTextHolder}>
                    <div className={classes.cardTextTitleHolder}>
                      <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                      <h5 className={classes.cardTextTitle}>  <strong><span className={classes.cardTextTitleAccent}>From</span> {formatter.format(v.price)}</strong></h5>
                      <p className={classes.cardTextTitleAccentMore}>More Details</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
    </>
  );
};

export default ShowRoom;
